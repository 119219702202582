import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { color, fontSize } from 'styles/theme'
import './header.css'

import Icon from 'components/icons'

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 0 0 auto;
  ${media.lg`
  height: ${(props) => (props.article ? '64px' : '')};
  `}
  ${media.sm`
    display: ${(props) => (props.article ? 'flex' : 'block')};
  `};
  z-index: 10;
  height: ${(props) => (props.article ? '74px' : '')};
  ${media.xs`
    display: block;
    height: 112px;
  `}
`

const LogoWrapper = styled.div`
  padding: 24px 0 24px 24px;
  ${media.sm`
    padding: ${(props) => (props.article ? '24px 0 24px 24px' : '24px 0 0 0')};
  `};
  ${media.xs`
    padding: 24px 0 0 0;
  `}
`

const NameLink = styled.a`
  text-decoration: none;
`

const Name = styled.h1`
  white-space: nowrap;
  font-size: ${fontSize.f6};
  text-align: left;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.8px;
  ${media.sm`
    text-align: center;
    font-size: ${fontSize.f7};
  `};
`

const NameArticle = styled.div`
  white-space: nowrap;
  font-size: ${fontSize.f5};
  font-weight: 700;
  text-align: left;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: ${color.grey900};
  ${media.xs`
    text-align: center;
  `};
`

const Role = styled.div`
  color: ${color.grey700};
  text-align: left;
  line-height: 1.4;
  font-size: ${fontSize.f4};
  ${media.sm`
    text-align: center;
    font-size: ${fontSize.f6};
  `};
`

const SocialLinks = styled.div`
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: auto auto auto;
  padding: 15px 24px 0 24px;
  ${media.sm`
    padding: ${(props) => (props.article ? '15px 12px 0 8px' : '4px 0 0 0')};
    grid-column-gap: 0;
  `}
  ${media.xs`
    padding: 0;
  `}
  justify-content: center;
`

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 20px;
  color: ${color.grey900};
  border: 1px solid white;
  &:hover {
    border: 1px solid ${color.grey150};
    background: ${color.grey150};
    color: ${color.grey900};
  }
  &:active {
    color: ${color.grey900};
  }
  &:visited {
    color: ${color.grey900};
  }
`

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  color: inherit;
  background: inherit;
`

export const InlineSvg = styled.svg`
  height: 24px;
  width: 24px;
  color: inherit;
  fill: currentColor;
`

const Tooltip = styled.div`
  padding: 2px 24px 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 300ms;
  ${media.sm`
    justify-content: center;
    padding-top: 6px;
  `}
`

const TooltipIcon = styled.div`
  transform: rotate(270deg);
  margin-left: 8px;
`

const TooltipText = styled.div``

const Header = (props) => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState('')

  const showTooltip = (tooltipText) => {
    setTooltipIsVisible(true)
    setTooltipText(tooltipText)
  }

  const hideTooltip = () => {
    setTooltipIsVisible(false)
  }

  return (
    <Div article={props.article}>
      <LogoWrapper article={props.article}>
        {props.article && (
          <NameLink href="/">
            <NameArticle article={props.article}>Qais Qais</NameArticle>
          </NameLink>
        )}
        {!props.article && <Name article={props.article}>Q A I S</Name>}
        {!props.article && <Role>Security Professional</Role>}
      </LogoWrapper>
      <div>
        <SocialLinks article={props.article}>
          <SocialLink
            href={`javascript:location.href = 'mailto:' + ['contact','qaisqais.com'].join('@')`}
            onMouseOver={() => showTooltip('Mail')}
            onFocus={() => showTooltip('Mail')}
            onMouseLeave={hideTooltip}
            onBlur={hideTooltip}
            aria-label="Send an email to Qais"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="m22.3592881 5.82427054v.59619016c0 .24378184-.1417446.46531584-.3630886.56747461l-8.9485541 4.13010189c-.6647377.306802-1.4305531.306802-2.0952908 0l-8.94855408-4.13010189c-.22134402-.10215877-.36308864-.32369277-.36308864-.56747461v-.59619016c0-.55012277.44596262-.99608539.9960854-.99608539h18.72640542c.5501228 0 .9960854.44596262.9960854.99608539zm-1.25 13.34754436h-18.21857622c-.69035594 0-1.25-.5596441-1.25-1.25v-8.52329148c0-.20710678.16789322-.375.375-.375.05424403 0 .10784237.01176807.15709707.03449228l8.78345405 4.0523453c.6647826.3067049 1.4305981.306593 2.095291-.0003062l8.7755375-4.05180448c.1880319-.08681729.4108411-.00476666.4976584.18326519.0227541.04928162.0345382.1029156.0345382.1571966v8.52310279c0 .6903559-.559644 1.25-1.25 1.25z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
          <SocialLink
            href="https://photo.qaisqais.com"
            target="blank"
            onMouseOver={() => showTooltip('Photo')}
            onFocus={() => showTooltip('Photo')}
            onMouseLeave={hideTooltip}
            onBlur={hideTooltip}
            aria-label="Qais Photo Gallery"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="M4.8541 8C3.83011 8 3 8.83011 3 9.8541V14.8571C3 16.8619 3 17.8643 3.45983 18.5961C3.69961 18.9777 4.02229 19.3004 4.4039 19.5402C5.13571 20 6.1381 20 8.14286 20H15.8571C17.8619 20 18.8643 20 19.5961 19.5402C19.9777 19.3004 20.3004 18.9777 20.5402 18.5961C21 17.8643 21 16.8619 21 14.8571V9.8541C21 8.83011 20.1699 8 19.1459 8C18.4436 8 17.8016 7.60322 17.4875 6.97508L16.6667 5.33333L16.6666 5.33329C16.5567 5.1134 16.5017 5.00345 16.4394 4.90782C16.1141 4.40882 15.5833 4.08078 14.9915 4.01299C14.8781 4 14.7552 4 14.5093 4H9.49071C9.24484 4 9.1219 4 9.00848 4.01299C8.41668 4.08078 7.8859 4.40882 7.56062 4.90782C7.49827 5.00346 7.44329 5.11342 7.33333 5.33333L6.51246 6.97508C6.19839 7.60322 5.55638 8 4.8541 8ZM14 13C14 14.1046 13.1046 15 12 15C10.8954 15 10 14.1046 10 13C10 11.8954 10.8954 11 12 11C13.1046 11 14 11.8954 14 13ZM16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13Z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
          <SocialLink
            href="https://blog.qaisqais.com"
            target="blank"
            onMouseOver={() => showTooltip('Blog')}
            onFocus={() => showTooltip('Blog')}
            onMouseLeave={hideTooltip}
            onBlur={hideTooltip}
            aria-label="Qais Blog"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="M 19.59375 13.789062 C 21.082031 12.996094 22.105469 11.359375 22.105469 9.589844 C 22.105469 6.320312 19.652344 4.292969 15.707031 4.292969 L 7.566406 4.292969 C 7.304688 4.292969 7.097656 4.5 7.097656 4.753906 L 7.097656 24.136719 C 7.097656 24.394531 7.304688 24.601562 7.566406 24.601562 L 15.898438 24.601562 C 20.316406 24.601562 23.0625 22.3125 23.0625 18.628906 C 23.0625 16.328125 21.710938 14.5 19.59375 13.789062 Z M 11.324219 7.523438 L 14.609375 7.523438 C 16.6875 7.523438 17.898438 8.542969 17.898438 10.292969 C 17.898438 12.082031 16.605469 13.0625 14.167969 13.0625 L 11.324219 13.0625 Z M 14.90625 21.367188 L 11.324219 21.367188 L 11.324219 15.832031 L 14.769531 15.832031 C 17.433594 15.832031 18.835938 16.777344 18.835938 18.570312 C 18.835938 20.398438 17.472656 21.367188 14.90625 21.367188 Z M 14.90625 21.367188" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
        </SocialLinks>
        {!props.article && (
          <Tooltip visible={tooltipIsVisible} article={props.article}>
            <TooltipText>{tooltipText}</TooltipText>
            <TooltipIcon>
              <Icon glyph="arrow" size={24} />
            </TooltipIcon>
          </Tooltip>
        )}
      </div>
    </Div>
  )
}

export default Header
