import React, { Fragment } from 'react'
import Link from 'gatsby-link'

import TwoColumns from 'components/twoColumns'
import SectionHeading from 'components/sectionHeading'
import Project from 'components/project'

const FirstBlogPostLink = <Link to="FirstBlogPost">Monday</Link>

const Work = () => {
  return (
    <TwoColumns
      wide
      leftColumn={<SectionHeading>Blog</SectionHeading>}
      rightColumn={
        <Fragment>
              <Project
                  title={FirstBlogPostLink}
                  link="10.10.2023"
              />
            </Fragment>
      }
    />
  )
}

export default Work
